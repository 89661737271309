<script>
import axios from 'axios'
export default {
    scopeName: 'wishlist',
    data() {
        return {
            initiator: null // Add to Wishlist button HTML element
        }
    },
    mounted() {
        /**
         * Init wishlist handler on element with [data-vue-init="add-to-wishlist"]
         */
        this.init(this.$parent.$options.inititator)
    },
    methods: {
        init(el) {
            this.initiator = el
            this.initiator.addEventListener('click', (event) => {
                event.preventDefault()
                event.stopImmediatePropagation()
                this.OnWishlistButtonClick(el)
            })
        },
        OnWishlistButtonClick(button) {
            this.initiator = button
            const $el = button
            const ADD_URL = $el.dataset.wishlistAdd
            const REMOVE_URL = $el.dataset.wishlistRemove
            const isAdded = $el.classList.contains('wishlist-added')
            const PRODUCT_ID =
                $el.dataset.productId ||
                $el?.form?.['product']?.value ||
                document.getElementById('product_id').value
            const FORM_KEY =
                $el?.form?.['form_key']?.value ||
                document.querySelector('[name="form_key"]').value
            let formData = new FormData()
            formData.append('form_key', FORM_KEY)
            formData.append('product', PRODUCT_ID)

            $el.disabled = true
            axios({
                method: 'POST',
                url: isAdded ? REMOVE_URL : ADD_URL,
                data: formData
            })
                .then((response) => {
                    this.handleWishlistResponse(response.data, isAdded)
                })
                .catch((error) => {
                    console.error(error)
                    const ERROR_MSG =
                        'Wystąpił problem. Spróbuj ponownie później.'
                    window.GoogleAnalyticsUniversal.prototype.errorSend({
                        category: 1,
                        action: ERROR_MSG
                    })
                    window.$apteline.modal.OpenModal({
                        title: ERROR_MSG
                    })
                })
                .finally(() => {
                    $el.disabled = false
                })
        },
        handleWishlistResponse(data, isAdded) {
            if (data.redirect) {
                window.location.href = data.redirect
            }
            if (data.error) {
                console.error(data.error)
                window.GoogleAnalyticsUniversal.prototype.errorSend({
                    category: 1,
                    action: data.error
                })
                window.$apteline.modal.OpenModal({
                    title: data.error
                })
            }
            if (data.success) {
                this.initiator.title = isAdded
                    ? 'Dodaj do ulubionych'
                    : 'Usuń z ulubionych'

                const $buttonText = this.initiator.querySelector(
                    '.link-wishlist__message'
                )
                if ($buttonText) {
                    $buttonText.innerHTML = !isAdded
                        ? 'Dodano do <br>ulubionych'
                        : 'Dodaj do <br>ulubionych'
                }

                this.initiator.classList.toggle('wishlist-added', !isAdded)
            }
        }
    },
    render: () => null
}
</script>
